import React, { FC, useState, useEffect } from "react";
import Link from "next/link";
import {
	HomeOutlined,
	MovieCreationOutlined,
	InsertDriveFileOutlined,
	SettingsOutlined,
} from "@mui/icons-material";
import { Avatar, Tooltip } from "@mui/material";
import styled from "styled-components";
import { black25, black65, black85, blue, white } from "config/colors";

const SidebarContainer = styled.aside`
	position: absolute;
	top: 0;
	bottom: 0;
	left: 0;
	width: 80px;
	height: 100vh;
	transition: background-color 200ms linear;
	background-color: ${({ isMinimized }) =>
		isMinimized ? "transparent" : black85};
`;

const NavBarButtonContainer = styled.ul`
	list-style-type: none;
	margin: 0;
	padding: 0;
	& > li:nth-child(${({ currentNavigation }) => currentNavigation + 1}) {
		background-color: ${blue};
	}
`;

const NavBarButton = styled.a`
	display: flex;
	flex-direction: column;
	align-items: center;
	padding: 14px 35px;
	margin-bottom: 8px;
	cursor: pointer;
	&:hover {
		background-color: ${blue};
	}
`;

const AvatarContainer = styled.div`
	position: absolute;
	bottom: 0px;
	width: 100%;
	padding: 14px 35px;
	display: flex;
	flex-direction: column;
	align-items: center;
	cursor: pointer;
`;

interface Props {
	currentNavigation: number;
	onNavigationSelect?: (key: string) => void;
	avatarContent?: string;
	isDefaultMinimized?: boolean;
}

const MainSidebar: FC<Props> = ({
	currentNavigation,
	onNavigationSelect,
	avatarContent,
	isDefaultMinimized = false,
}) => {
	const [isMinimized, setIsMinimized] = useState(false);
	const [currentHover, setCurrentHover] = useState(-1);
	const [SPRKLogoHover, setSPRKLogoHover] = useState(false);
	const setSPRKLogoHoverFalse = () => setSPRKLogoHover(false);
	const setSPRKLogoHoverTrue = () => setSPRKLogoHover(true);

	useEffect(() => {
		setIsMinimized(isDefaultMinimized);
	}, [isDefaultMinimized]);

	const isActiveCircleColor = (key: number) =>
		currentNavigation === key || currentHover === key
			? blue
			: "transparent";

	const renderActNowButton = () => (
		<Link href="">
			<a>
				<img
					width="50px"
					alt="SPRK logo"
					src={
						SPRKLogoHover
							? "/images/SPRKItalic-white.png"
							: "/images/SPRK-white.png"
					}
					onMouseOver={setSPRKLogoHoverTrue}
					onMouseLeave={setSPRKLogoHoverFalse}
					style={{
						cursor: "pointer",
						margin: "20px 15px",
					}}
				/>
			</a>
		</Link>
	);

	return (
		<SidebarContainer isMinimized={isMinimized}>
			<NavBarButtonContainer currentNavigation={currentNavigation}>
				<div style={{ height: 55 }}>{renderActNowButton()}</div>

				{!isMinimized && (
					<>
						<Tooltip
							title="Home"
							placement="right"
							aria-label="Go to home page"
						>
							<li>
								<Link href="/" passHref>
									<NavBarButton
										onMouseOver={() => setCurrentHover(1)}
										onMouseLeave={() => setCurrentHover(-1)}
									>
										<HomeOutlined
											style={{
												fontSize: 22,
												color: white,
											}}
										/>
									</NavBarButton>
								</Link>
							</li>
						</Tooltip>

						<Tooltip
							title="Projects"
							placement="right"
							aria-label="Go to projects page"
						>
							<li>
								<Link href="/projects" passHref>
									<NavBarButton
										onMouseOver={() => setCurrentHover(2)}
										onMouseLeave={() => setCurrentHover(-1)}
									>
										<MovieCreationOutlined
											style={{
												fontSize: 21,
												color: white,
											}}
										/>
									</NavBarButton>
								</Link>
							</li>
						</Tooltip>

						<Tooltip
							title="Documents"
							placement="right"
							aria-label="Go to documents page"
						>
							<li>
								<Link href="/documents" passHref>
									<NavBarButton
										onMouseOver={() => setCurrentHover(3)}
										onMouseLeave={() => setCurrentHover(-1)}
									>
										<InsertDriveFileOutlined
											style={{
												fontSize: 21,
												color: white,
											}}
										/>
									</NavBarButton>
								</Link>
							</li>
						</Tooltip>

						<Tooltip
							title="Settings"
							placement="right"
							aria-label="Go to settings page"
						>
							<li>
								<Link href="/settings" passHref>
									<NavBarButton
										onMouseOver={() => setCurrentHover(4)}
										onMouseLeave={() => setCurrentHover(-1)}
									>
										<SettingsOutlined
											style={{
												fontSize: 21,
												color: white,
											}}
										/>
									</NavBarButton>
								</Link>
							</li>
						</Tooltip>

						{avatarContent && (
							<div>
								<Link href="/profile">
									<a>
										<Tooltip
											title="Profile"
											placement="right"
											aria-label="Go to profile page"
										>
											<AvatarContainer
												onMouseOver={() =>
													setCurrentHover(5)
												}
												onMouseLeave={() =>
													setCurrentHover(-1)
												}
											>
												<Avatar
													style={{
														backgroundColor:
															black65,
														color: black25,
														borderWidth: 2,
														borderColor:
															isActiveCircleColor(
																5
															),
														borderStyle: "solid",
														boxSizing:
															"content-box",
														width: 35,
														height: 35,
														fontSize: 17,
													}}
												>
													{avatarContent}
												</Avatar>
											</AvatarContainer>
										</Tooltip>
									</a>
								</Link>
							</div>
						)}
					</>
				)}
			</NavBarButtonContainer>
		</SidebarContainer>
	);
};

export default MainSidebar;
