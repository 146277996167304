import React, { FC } from "react";
import { useRouter } from "next/router";
import Link from "next/link";
import {
	HomeOutlined,
	MovieCreationOutlined,
	InsertDriveFileOutlined,
	SettingsOutlined,
} from "@mui/icons-material";
import { Breadcrumbs } from "@mui/material";
import { makeStyles } from "@mui/styles";
import styled from "styled-components";

import { useAuth } from "components/common/HOC/AuthProvider";
import { black200 } from "config/colors";
import MainSidebar from "./components/MainSidebar";

const useStyles = makeStyles((theme) => ({
	link: {
		display: "flex",
	},
	icon: {
		marginTop: theme.spacing(0.1),
		marginRight: theme.spacing(0.5),
		width: 20,
		height: 20,
	},
}));

const BreadCrumbLink = styled.a`
	display: flex;
	text-decoration: none;
	color: inherit;
	margin: 0;
`;

const Layout = styled.section`
	display: flex;
	flex-direction: row;
	height: 100vh;
	padding-left: ${({ hasLeftSidebar }) => (hasLeftSidebar ? "80px" : 0)};
	padding-right: ${({ hasRightSidebar }) => (hasRightSidebar ? "96px" : 0)};
	background: ${black200};
`;

const Content = styled.main`
	box-sizing: border-box;
	width: ${({ hasLeftSidebar }) =>
		hasLeftSidebar ? "calc(100vw - 80px)" : "100vw"};
	overflow: auto;
`;

const Header = styled.div`
	display: flex;
	justify-content: space-between;
`;

const WelcomeMessage = styled.h1`
	font-size: 19px;
	font-weight: 300;
	margin: 16px;
`;

interface Props {
	children?: React.ReactNode;
	selectedKeys: string[];
	breadCrumbItems?: string[];
	hasRightSidebar?: boolean;
	hasLeftSidebar?: boolean;
	isMainSidebarMinimized?: boolean;
}

const Navigation: FC<Props> = ({
	children,
	selectedKeys = ["1"],
	breadCrumbItems,
	hasRightSidebar = false,
	hasLeftSidebar = true,
	isMainSidebarMinimized = false,
}) => {
	const classes = useStyles();
	const { user }: any = useAuth();

	const initialsRegex = new RegExp(/(\p{L}{1})\p{L}+/, "gu");
	let initials = [...user.displayName.matchAll(initialsRegex)] || [];
	const userInitials = (
		(initials.shift()?.[1] || "") + (initials.pop()?.[1] || "")
	).toUpperCase();

	const navigationOptions = {
		1: "",
		2: "projects",
		3: "documents",
		4: "settings",
		5: "profile",
	};

	const router = useRouter();
	const baseRouterPath = router.pathname.split("/")[1];
	const currentNavigation = Object.keys(navigationOptions).find(
		(key) => navigationOptions[key] === baseRouterPath
	);

	const breadCrumbIcons = {
		Home: <HomeOutlined className={classes.icon} />,
		Projects: <MovieCreationOutlined className={classes.icon} />,
		Documents: <InsertDriveFileOutlined className={classes.icon} />,
		Settings: <SettingsOutlined className={classes.icon} />,
	};

	const breadCrumbLinks = {
		Home: "",
		Projects: "projects",
		Documents: "documents",
		Settings: "settings",
	};

	const showWelcomeMessage =
		breadCrumbItems &&
		breadCrumbItems.length === 1 &&
		breadCrumbItems.includes("Home");

	const renderBreadCrumbs = () =>
		breadCrumbItems && (
			<Breadcrumbs
				style={{
					margin: 16,
					display: "flex",
					alignItems: "center",
				}}
			>
				{breadCrumbItems.map((breadCrumb, i) => (
					<Link
						href={
							breadCrumbLinks?.[breadCrumb] !== undefined
								? `/${breadCrumbLinks[breadCrumb]}`
								: ""
						}
						key={i}
						passHref
					>
						<BreadCrumbLink>
							{breadCrumbIcons[breadCrumb]}
							<span style={{ cursor: "pointer" }}>
								{breadCrumb}
							</span>
						</BreadCrumbLink>
					</Link>
				))}
			</Breadcrumbs>
		);

	return (
		<Layout
			hasRightSidebar={hasRightSidebar}
			hasLeftSidebar={hasLeftSidebar}
		>
			{hasLeftSidebar && (
				<MainSidebar
					currentNavigation={parseInt(currentNavigation)}
					avatarContent={userInitials}
					isDefaultMinimized={isMainSidebarMinimized}
				/>
			)}

			<Content hasLeftSidebar={hasLeftSidebar}>
				<Header>
					{renderBreadCrumbs()}
					{showWelcomeMessage && (
						<WelcomeMessage>{`Welcome ${
							user?.displayName || "creator"
						}!`}</WelcomeMessage>
					)}
				</Header>
				{children}
			</Content>
		</Layout>
	);
};

export default Navigation;
