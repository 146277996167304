// @ts-nocheck
import { useRef, useEffect } from "react";
import { createPortal } from "react-dom";
/**
 * Convert a hex color to a RGB color
 */
const hexToRgb = (color = "#000000") => {
	const hex = color.replace("#", "");
	const r = parseInt(hex.substring(0, 2), 16);
	const g = parseInt(hex.substring(2, 4), 16);
	const b = parseInt(hex.substring(4, 6), 16);
	return { r, g, b };
};

export function hexToRgba(color = "#000000", opacity = 100) {
	const { r, g, b } = hexToRgb(color);
	return "rgba(" + r + "," + g + "," + b + "," + opacity / 100 + ")";
}

export const capitalize = (text: string) =>
	text[0].toUpperCase() + text.substr(1, text.length);
export const weightDescription = (weight: number) =>
	weight == 400 ? "Regular" : weight == 500 ? "Medium" : "Bold";

export const isPercentage = (val: string) =>
	typeof val == "string" && val.indexOf("%") > -1;

export const percentToPx = (value: any, comparativeValue: number) => {
	if (value.indexOf("px") > -1 || value == "auto" || !comparativeValue)
		return value;
	const percent = parseInt(value);
	return (percent / 100) * comparativeValue + "px";
};
export const pxToPercent = (value: any, comparativeValue: number) => {
	// if ( typeof value == 'number' ) return;
	const val = (Math.abs(value) / comparativeValue) * 100;
	if (value < 0) return -1 * val;
	else return Math.round(val);
};
export const getElementDimensions = (element: HTMLElement) => {
	const computedStyle = getComputedStyle(element);

	let height = element.clientHeight,
		width = element.clientWidth; // width with padding

	height -=
		parseFloat(computedStyle.paddingTop) +
		parseFloat(computedStyle.paddingBottom);
	width -=
		parseFloat(computedStyle.paddingLeft) +
		parseFloat(computedStyle.paddingRight);

	return {
		width,
		height,
	};
};

/** Start: Relative time functions */

// in miliseconds
const units = {
	year: 24 * 60 * 60 * 1000 * 365,
	month: (24 * 60 * 60 * 1000 * 365) / 12,
	day: 24 * 60 * 60 * 1000,
	hour: 60 * 60 * 1000,
	minute: 60 * 1000,
	second: 1000,
};

const rtf = new Intl.RelativeTimeFormat("en", { numeric: "auto" });

export const getRelativeTime = (d1, d2: any = new Date()) => {
	if (d1 === "...") return "...";

	const elapsed = d1 - d2;

	if (Number.isNaN(elapsed)) return "";

	// "Math.abs" accounts for both "past" & "future" scenarios
	for (var u in units)
		if (Math.abs(elapsed) > units[u] || u == "second") {
			if (u == "second") return "seconds ago";
			return rtf.format(Math.round(elapsed / units[u]), u);
		}
};

/** End: Relative time functions */

export const useDraggableInPortal = () => {
	const self = useRef({}).current;

	useEffect(() => {
		const div = document.createElement("div");
		div.style.position = "absolute";
		div.style.pointerEvents = "none";
		div.style.top = "0";
		div.style.width = "100%";
		div.style.height = "100%";
		self.elt = div;
		document.body.appendChild(div);
		return () => {
			document.body.removeChild(div);
		};
	}, [self]);

	return (render) =>
		(provided, ...args) => {
			const element = render(provided, ...args);
			if (provided.draggableProps.style.position === "fixed") {
				return createPortal(element, self.elt);
			}
			return element;
		};
};

export const formatDate = (timestamp: string | number): null | string => {
	if (timestamp === null) return null;
	const dateOptions: Intl.DateTimeFormatOptions = {
		month: "short",
		day: "numeric",
		year: "numeric",
	};
	const date = new Date(timestamp);
	const formattedDate = date.toLocaleString("en-gb", dateOptions);
	return `${formattedDate}`;
};

export const formatDateTime = (timestamp: string | number): null | string => {
	if (timestamp === null) return null;
	const dateOptions: Intl.DateTimeFormatOptions = {
		month: "short",
		day: "numeric",
		year: "numeric",
		hour: "2-digit",
		minute: "2-digit",
		hour12: false,
	};
	const date = new Date(timestamp);
	const formattedDate = date.toLocaleString("en-gb", dateOptions);
	return `${formattedDate}`;
};

export const validateEmail = (email: string) => {
	var re = /\S+@\S+\.\S+/;
	return re.test(email);
};
