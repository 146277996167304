import { hexToRgba } from "utils/helpers";

export const black15o20 = hexToRgba("#D9D9D9", 20);
export const black15 = "#D9D9D9";
export const black25 = "#BFBFBF";
export const black45 = "#8C8C8C";
export const black65 = "#595959";
export const black65o20 = hexToRgba("#595959", 20);
export const black85 = "#262626";
export const black200 = "#FAFAFA";
export const black600 = "#F0F0F0";
export const black800 = "#bdc3c7";
export const blue10 = "#E6F7FF";
export const blue50 = "#91D5FF";
export const blue100 = "#CED8E0";
export const blue200 = hexToRgba("#1890FF", 20);
export const blue = "#1890FF";
export const orange = "#F8891B";
export const orange500 = "#EBA14D";
export const red = "#e74c3c";
export const green = "#52C41A";
export const greenO20 = hexToRgba("#52C41A", 20);
export const yellow = "#f1c40f";
export const white = "#FFFFFF";
export const whiteo80 = hexToRgba("#FFFFFF", 80);

export const randomColors = [
	"#1abc9c",
	"#16a085",
	"#f1c40f",
	"#f39c12",
	"#2ecc71",
	"#27ae60",
	"#8e44ad",
	"#d35400",
	"#3498db",
	"#2980b9",
	"#e74c3c",
	"#c0392b",
	"#9b59b6",
	"#8e44ad",
];
